import React, { useEffect, useRef, useState } from 'react';

const FacebookChatPlugin = () => {

    const [initChatStatus, setInitChatStatus] = useState(false);
    const MessengerRef = useRef();


    useEffect(() => {
        const timeout = setTimeout(() => {
            MessengerRef?.current?.setAttribute('page_id', "1447838725530043");
            MessengerRef?.current?.setAttribute('attribution', 'biz_inbox');
            setInitChatStatus(true);
        }, 2000);

        return () => {
            clearTimeout(timeout)
        }
    }, []);

    useEffect(() => {
        if (initChatStatus) {
            if (typeof window.FB === "undefined") {
                initFbChat();
            } else {
                window.FB.CustomerChat.show(true);
            }
        }

        return () => {
            if (typeof window.FB !== "undefined") {
                window.FB.CustomerChat.hide();
            }
        }
    }, [initChatStatus]);

    const initFbChat = () => {
        window.fbAsyncInit = function () {
            window.FB.init({
                xfbml: true,
                version: 'v14.0'
            });
        };

        // Load the Facebook SDK asynchronously
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    return (
        <div
            ref={MessengerRef}
            className="fb-customerchat"
            attribution="setup_tool"
            //   page_id="MixedRealityVietnam"
            page_id="100064551345533"
        //   page_id="1447838725530043"
        />
    );
};

export default FacebookChatPlugin;