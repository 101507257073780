import { Link } from 'react-router-dom';
import '../App.css';
import FacebookChatPlugin from "../FacebookChatPlugin";
import React from 'react';

function Support() {
  return (
    <div className="App">
      Facebook Page
      <Link to={"/"}><span>Home</span></Link>
      <FacebookChatPlugin />
    </div>
  );
}

export default Support;
